// i18next-extract-mark-ns-start markets-motorsport-electric

import { MARKETS_CAPABILITIES_ELECTRIC_NAV, MOTORSPORT_NAV, ELECTRIC_DRIVE_PRODUCTS_NAV, LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MarketMotorsportPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const ignite = getImage(data.ignite.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<h2>Battery, motor and control parameter simulation</h2>
						<p>
							The road to a net zero future includes different electrification technologies for different vehicle types.
							Full electric racing provides the ultimate in zero emissions racing and is being seen increasingly across single seater,
							off-road SUV’s and powerboats.
						</p>
						<p>
							The engineering challenge for teams and manufacturers is achieving instant power, top speed and drivability,
							whilst balancing range, cost, weight and battery / motor durability.
						</p>
						<p>
							In the early stages of development physical prototype modelling is expensive,
							requiring virtual simulation tools to answer many of the 'What ifs' before committing to a design path.
							Complete vehicle system simulation models are vital in the selection of the correct balance of components
							to meet performance, cost and drivability requirements for full electric racing machines
							as well as predicting their behaviour under different duty cycles.
						</p>
						<p>
							These systems are modelled using IGNITE.
						</p>
					</Trans>
					{ignite && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={ignite}
								alt="IGNITE"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_2">
						<p>
							IGNITE, is a complete physics-based hybrid simulation package. With a library system of building blocks,
							the user can quickly and accurately model complete systems, analysing the electric motor control and overall engine performance.
						</p>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Solutions")}
						className="widget__sibling__pages">
						<AssetList>
							{MOTORSPORT_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_OurCapabilities")}
						className="widget__sibling__pages">
						<AssetList>
							{MARKETS_CAPABILITIES_ELECTRIC_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Products")}
						className="widget__sibling__pages">
						<AssetList>
							{ELECTRIC_DRIVE_PRODUCTS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>
		</Page>
	);
};

export default MarketMotorsportPage;

export const pageQuery = graphql`
	query ($language: String!) {
		locales: allLocale(
			filter: { ns: { in: ["markets-motorsport-electric", "_common"] }, language: { eq: $language } }
		) {
			edges {
				node {
				ns
				data
					language
				}
			}
		}
		banner: file(relativePath: { eq: "content/markets/motorsport/Realis-Simulation_1915_MSport2_cropped.jpg" }) {
			...imageBreaker
		}
		ignite: file(relativePath: { eq: "content/logos/IGNITE.png" }) {
			...imageBreaker
		}
		resources: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { regex: "/resources/" }
				fields: { language: { eq: $language } }
				frontmatter: { capabilities: { in: "Electrification" } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 6
		) {
			nodes {
				fields {
					slug
				}
				frontmatter {
					title
					summary
					image {
						...imageStandard
					}
					type
				}
			}
		}
	}
`;
